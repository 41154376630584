import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid, Paper} from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, } from '@mui/material';
import {getAllGroups, getAllReservation} from '../../api/publicApi'
// import {getUserById} from '../../api/userApi'
// import { getAllReservation } from '../../api/publicApi';

const GroupCalendar = () => {
    // const { id } = useParams();
// console.log(id)
  const [groupData, setGroupData] = useState([]);
//   const [users, setUsers] = useState([]);
  const [rezervacije, setRezervacije] = useState([]);

  useEffect(() => {
    async function fetchGroup() {
      try {
        const data = await getAllGroups();
        setGroupData(data);
        const dataReservation = await getAllReservation();
        // console.log("dataReservation",dataReservation)
        setRezervacije(dataReservation);
        // console.log("dataReservation",dataReservation)
        // if (users.length === 0) {
        //   const members = await getGroupMembers();
        //   setUsers(members);
        // }
      } catch (error) {
        console.error(error);
      }
    }
    fetchGroup();
  }, []);
  
//   async function getGroupMembers() {
//     const members = [];
//     for (let i = 0; i < groupData.members?.length; i++) {
//       const member = await getUserById(groupData.members[i]._id);
//       members.push(member);
//     }
//     return members;
//   }
  
  function onRemoveUser(userId) {
    // implementacija funkcije za uklanjanje korisnika iz grupe
    // pozivom API-ja, ažuriranje stanja, itd.
  }

  const tableCellStyle = {
  color: 'black', // Postavite boju teksta na crnu
  border:"1px solid",
};

  // Organizujemo rezervacije po danima u sedmici
  const weeklySchedule = {
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
    Sunday: [],
  };
  // console.log(rezervacije)
  // Popunjavamo sedmični raspored sa rezervacijama
  rezervacije.forEach((reservation) => {
    console.log("reservation",reservation)
    const ostaloMjesta = reservation.group.maxCapacity - reservation.attendees.length;

    const trener = reservation.group.trainer.member.user.firstName + " " + reservation.group.trainer.member.user.lastName;

    const color = reservation.color;

    const day = reservation.day;
    //  console.log("day",day)
    const timeSlot = `${reservation.startTime} - ${reservation.endTime}`;
    //  console.log("timeSlot",timeSlot)
    const groupName = reservation.group.name;
    // console.log("groupName",groupName)
    weeklySchedule[day].push({ timeSlot, groupName, ostaloMjesta, trener, color });
  });

  // Prvo, izdvojite sva vremena iz niza rezervacija
const reservationTimes = rezervacije.map((reservation) =>{
  const timeSlot = `${reservation.startTime} - ${reservation.endTime}`;
  return timeSlot
});
//   console.log("reservationTimes", reservationTimes)

  // Zatim, pretvorite ih u jedinstveni niz koristeći Set kako biste izbjegli dupliciranje
const uniqueTimes = [...new Set(reservationTimes)];
//   console.log("uniqueTimes",uniqueTimes)
  // Sortirajte vremena koristeći funkciju za usporedbu
uniqueTimes.sort((time1, time2) => {
  const [start1, end1] = time1.split(' - ');
  const [start2, end2] = time2.split(' - ');

  const [hours1, minutes1] = start1.split(':').map(Number);
  const [hours2, minutes2] = start2.split(':').map(Number);

  // Usporedite po satima i minutama početka vremena
  if (hours1 < hours2) return -1;
  if (hours1 > hours2) return 1;
  if (minutes1 < minutes2) return -1;
  if (minutes1 > minutes2) return 1;

  // Ako su sati i minute isti, usporedite po završetku vremena
  const [endHours1, endMinutes1] = end1.split(':').map(Number);
  const [endHours2, endMinutes2] = end2.split(':').map(Number);

  if (endHours1 < endHours2) return -1;
  if (endHours1 > endHours2) return 1;
  if (endMinutes1 < endMinutes2) return -1;
  if (endMinutes1 > endMinutes2) return 1;

  return 0;
});
    const daysOfWeek = Object.keys(weeklySchedule);
    
  const timeSlots = [
    ...uniqueTimes, // Dodajte jedinstvena vremena iz rezervacija
    // '06:00 - 07:00',
    // '07:00 - 08:00',
  ];
//  
  // console.log(daysOfWeek)
  if (!groupData) {
    return <div>Ucitavanje...</div>;
  }

//   const formatTime = (time) => {
//   const [start, end] = time.split(' - ');
//   return `${start.slice(0, 5)} - ${end.slice(0, 5)}`;
// };
const translateToCroatian = (day) => {
    switch (day) {
      case 'Monday':
        return 'Ponedjeljak';
      case 'Tuesday':
        return 'Utorak';
      case 'Wednesday':
        return 'Srijeda';
      case 'Thursday':
        return 'Četvrtak';
      case 'Friday':
        return 'Petak';
      case 'Saturday':
        return 'Subota';
      case 'Sunday':
        return 'Nedjelja';
      default:
        return '';
    }
  };
// console.log(weeklySchedule)
  return (
    <Container>
      {/* <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Box>
            <img src={groupData.image} alt={groupData.name} style={{ maxWidth: '100%', height: 'auto' }} />
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Box>
            <Typography variant="h4">{groupData.name}</Typography>
            <Typography variant="body1">{groupData.description}</Typography>
          </Box>
          <Box >
        <Typography variant="h5">Članovi</Typography>
        <List>
        {users?.map((member, index) => (
        <ListItem key={index}>
          <ListItemAvatar>
            {member.photo ? (
              <Avatar src={member.photo} />
            ) : (
              <Avatar>{member.firstName.charAt(0)}</Avatar>
            )}
          </ListItemAvatar>
          <ListItemText
            primary={`${member.firstName} ${member.lastName}`}
            // secondary={member.membership ? `Br. clanske: ${member.membership.brojClanske}` : ''}
          />
          {member.membership && (
            <ListItemText secondary={`Paket: ${member?.membership?.plan?.nazivPaketa}`} 
            style={{ color: 'white'}}
            classes={{ secondary: 'white-text' }}
            />
          )}
          {member.membership && (
            <ListItemText secondary={`Br. clanske: ${member.membership.brojClanske}`} 
            style={{ color: 'white'}}
            classes={{ secondary: 'white-text' }}
            />
          )}
          {onRemoveUser && (
            <IconButton onClick={() => onRemoveUser(member._id)}>
              <ClearIcon style={{ color: 'red' }}/>
            </IconButton>
          )}
        </ListItem>
      ))}
        </List>
      </Box>
        </Grid>
      </Grid> */}
      <Box mt={12}>
        {/* <Typography variant="h5">Raspored</Typography> */}
        <Grid container spacing={0} style={{ justifyContent: "center", alignItems: "center" }}>
        <Container>
      <Typography variant="h4" gutterBottom>
        Sedmični Raspored
      </Typography>
<TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            {daysOfWeek.map((day) => (
              <TableCell key={day} align="center" style={{ ...tableCellStyle, textTransform: 'uppercase', fontWeight: 'bold' }}>
              {translateToCroatian(day)}
            </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {timeSlots.map((timeSlot) => (
            <TableRow key={timeSlot}>
              <TableCell style={tableCellStyle}>{timeSlot}</TableCell>
              {daysOfWeek.map((day) => {
        const reservation = weeklySchedule[day].find((res) => res.timeSlot === timeSlot);
        return (
          <TableCell key={day} align="center" style={tableCellStyle}>
            {reservation ? (
              <>
           {console.log(reservation)}
                <div style={{fontSize:"14px", fontWeight:"bold", color:reservation.color}}>{reservation.groupName}</div>
                <div style={{ fontWeight:"bold", color:reservation.color}}>Trener: {reservation.trener}</div>
                { reservation.ostaloMjesta > 0 ?
                <div  style={{ color:reservation.color}}>Slobodno mjesta: {reservation.ostaloMjesta}</div>
                :
                <div>Popunjeno</div>
                }
              </>
            ) : (
              ''
            )}
          </TableCell>
        );
      })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Container>
        </Grid>
      </Box>
    </Container>
  );
};

export default GroupCalendar;
