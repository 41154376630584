import React, { useState,useEffect } from 'react';
import {createLogin} from '../../api/authApi'
import { useNavigate } from 'react-router-dom';
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Link from "@material-ui/core/Link";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import LockIcon from '@mui/icons-material/Lock';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
// import image from "./Images/image.jpg";
// import authService from "./../service/authService";
import { fetchProtectedData } from '../../api/fetchProtectedData';
import {getAllGyms} from '../../api/gymApi'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useContext } from 'react';
import { UserContext } from '../../context/UserProvider'; // Prilagodite putanju prema stvarnom mjestu gdje se nalazi UserContext



function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://stayfit.ba/">
        StayFit
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    // backgroundImage: `url(${image})`,
    backgroundRepeat: "no-repeat",
    // backgroundPosition: "center",
    // backgroundSize: "cover",
    backgroundColor:
      theme.palette.type === "light"
        ? theme.palette.grey[50]
        : theme.palette.grey[900],

    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "url('../assets/img/gymElita.jpg')",
    // backgroundImage: "url('../assets/img/a4sback.jpg')", // Postavite putanju do vaše slike
    backgroundSize: "cover", // Opciono, postavite veličinu pozadinske slike
    backgroundPosition: "center center" // Opciono, postavite poziciju pozadinske slike
  },
  size: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },

  paper: {
    margin: theme.spacing(2, 6),
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  avatar: {
    margin: theme.spacing(0),
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  },
  image:{
    width:"50%"
  }
}));

const Login = ({ setIsAuthenticated }) => {
  const { setUser } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [gyms, setGyms] = useState([]);
  const [gym, setGym] = useState("");
  const gymId = gym._id;

  // console.log(gym)
  const navigate = useNavigate();

  useEffect(()=>{

    async function handleGyms(){
      const handledGyms = await getAllGyms()
      setGyms(handledGyms)
    }
  
    handleGyms()
  
  },[])

  // console.log(gyms)
  const handleChangeGym = (event) => {
    // Pretvori objekat u JSON string pri čuvanju u localStorage
    setGym(event.target.value);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await createLogin({ email, password, gymId });

      // console.log(response)
      if (response.status === 200) {
        const { token, role, user } = response;
        // setUser(user)
        // const hashedRole = await hashRole(role); // Generiranje heša uloge
        localStorage.setItem('token', token);
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('defaultGym', JSON.stringify(gym));
        // localStorage.setItem('userRole', role); // Spremanje heširane uloge
        localStorage.setItem('userId', user._id); 
      // Dohvatite zaštićene podatke nakon uspješne prijave
        setIsAuthenticated(true);
         fetchProtectedData();
        // navigate('/');
        window.location.href = '/';
        // toast.success('Uspješno ste se prijavili.');
      } else {
        toast.error('Došlo je do greške prilikom prijave.');
      }
    } catch (error) {
      console.error('Greška prilikom prijave', error.response.data.message);
      if(error.response.data.message === "Invalid credentials"){
        toast.error("Pogrešni kredencijali za prijavu");
        
      }else{
        toast.error("Greška prilikom prijave");
      }
    }
  };

  const classes = useStyles();

  return (
    <Grid container component="main" 
    className={classes.root}
    >
       <ToastContainer />
      <CssBaseline />
      {/* <Grid item xs={false} sm={4} md={7} className={classes.image} /> */}
      <Grid
        className={classes.size}
        item
        xs={12}
        sm={8}
        md={5}
        component={Paper}
        elevation={1}
        square
      >
        <div className={classes.paper}>
        {/* <img src="../assets/img/a4s_logo_dark.png" alt="Slika" className={classes.image} /> */}
        <img src="../assets/img/elita.png" alt="Slika" className={classes.image} />
          <Avatar 
          className={classes.avatar}
          >
            <LockIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Prijavi se
          </Typography>
          <form 
          className={classes.form} 
          onSubmit={handleLogin}
          noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              autoFocus
            />
            <TextField
            // onChange={(event)=>handelAccount("password",event)}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="current-password"
            />
                  <InputLabel id="demo-simple-select-label" style={{ color: "black" }}>Odaberi gym</InputLabel>
                <Select
                variant="outlined"
                margin="normal"
                required
                fullWidth
                    labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={gymId}
                    label="Gym"
                    onChange={handleChangeGym}
                    style={{ border: "1px solid gray", color: "gray" }}
                    // className="custom-select" // Dodajte ovu liniju
                >
                  {gyms?.map((e, key)=>{
                    return <MenuItem style={{ color: "gray" }} key={key} value={e}>{e?.naziv}</MenuItem>
                  })
                  }
                </Select>
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Zapamti me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              // onClick = {handelLogin}
            >
              Prijava
            </Button>
            <Grid container>
              <Grid item>
                <Link href="#" variant="body2">
                  {"Nemate račun? Registrijte se"}
                </Link>
              </Grid>
            </Grid>
            <Box mt={5}>
              <Copyright />
            </Box>
          </form>
        </div>
      </Grid>
    </Grid>
  );
}

export default Login;

// import React, { useState } from 'react';
// import {createLogin} from '../../api/authApi'
// import { useNavigate } from 'react-router-dom';


// const Login = () => {
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const navigate = useNavigate(); // Replace useHistory with useNavigate

//   const handleLogin = async (e) => {
//     e.preventDefault();
    
//     try {
//       // const response = await createLogin({ email, password });
//       const response = await createLogin({ email, password }, { headers: { Accept: 'application/json' } });

//       // console.log(response)

//       if (response.status === 200) {
//         console.log("ok")
//         // const { token } = await response.json();
//         const { token } = response;

//         console.log(token)
//         // Spremanje JWT tokena u lokalnu pohranu
//         localStorage.setItem('token', token);

//         // Redirekcija na zaštićenu rutu
//         navigate('/'); // Use navigate instead of history.push
//       } else {
//         // Prikaz pogreške pri prijavi
//         console.error('Neuspješna prijava');
//       }
//     } catch (error) {
//       console.error('Greška prilikom prijave', error);
//     }
//   };

//   return (
//     <form onSubmit={handleLogin}>
//       <input
//         type="teemail"
//         placeholder="email"
//         value={email}
//         onChange={(e) => setEmail(e.target.value)}
//       />
//       <input
//         type="password"
//         placeholder="Lozinka"
//         value={password}
//         onChange={(e) => setPassword(e.target.value)}
//       />
//       <button type="submit">Prijavi se</button>
//     </form>
//   );
// };

// export default Login;
