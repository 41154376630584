import React, {useState, useEffect} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate} from 'react-router-dom';
// import Dialog from '@material-ui/core/Dialog';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
// import DialogActions from '@material-ui/core/DialogActions';
// import EditIcon from '@mui/icons-material/Edit';
// import UpdateIcon from '@mui/icons-material/Update';
// import DeleteIcon from '@mui/icons-material/Delete';
// import Badge from '@mui/material/Badge';
// import RemainingDays from "../../components/RemainingDays";
import { deleteReklama, getAllReklama } from '../../api/reklamaApi';
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;
import {TailSpin} from 'react-loader-spinner';
import { ToastContainer, toast } from 'react-toastify';

const Reklame = () => {
  let id = useParams();
  const gymId = id.gymID
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [equpmentToDelete, setEqupmentToDelete] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isExerciseCreated, setIsExerciseCreated] = useState(false);

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [reklame, setReklama] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  // const [gym, setGym] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedData = await getAllReklama();
        setReklama(fetchedData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, []);

  // console.log(equipments)
  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };



  const handleDelete = (cell) => {
    // console.log(cell)
    setEqupmentToDelete(cell);
    setDeleteDialogOpen(true);
  };
  
  const handleCancelDelete = () => {
    setEqupmentToDelete(null);
    setDeleteDialogOpen(false);
    // setPassword('');
  };
  useEffect(() => {
    if (!loading && isExerciseCreated) {
      toast.success('Uspješno ste obrisali reklamu.');
      setIsExerciseCreated(false); // Resetujte stanje nakon prikazivanja poruke
    }
  }, [loading, isExerciseCreated]);

  

  const linkRender = (cell) => {
    // console.log(cell.row); // Log cell.row to check its value
    if (!cell?.row || !cell.row.link) {
      return <p>Nema naziva</p>; // Retuvarn a placeholder value or an error message
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.link}
      </p>
    );
  }
  


const fotografijaRender = (cell) => {
  // console.log(cell.row); // Log cell.row to check its value
  if (!cell?.row || !cell.row.slika) {
    return null; // Return null or a placeholder value if user data is undefined
  }
  const handleClick = () => {
    navigate(`/oprema/${cell.row._id}/detalj`, { state: cell.row });
  };
  return (
    <>
      <img 
        alt="Slika nije pronadjena"
        // src={`${imageUrl}/${cell.row.image}` }
        src={cell.row.slika} 
        style={{width:"100%"}}
        onClick={handleClick}
  
      />
    </>
  );
};


  const columns = [

    { field: 'image', headerName: 'Foto', width: 400,
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'link', headerName: 'Link', width: 300, 
    renderCell: (params) => {
        return linkRender(params);
    }
    }
  ];
  // console.log(membership)
  return (
    <>
    {loading ? (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
          </div>
        ) : (
    <Box m="20px">
       <ToastContainer />
      <Header
        title="REKLAME"
        // subtitle="List of Contacts for Future Reference"
      />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/reklame/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova reklama
          </Button>
        </Link>
        </Box>
      <Box
        m="0px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <TextField
          id="search"
          label="Pretraga"
          variant="outlined"
          size="small"
          value={searchValue}
          onChange={handleSearch}
          style={{ marginBottom: "10px", width: "250px" }}
        />
        <DataGrid
           pageSize={9}
           rowsPerPageOptions={[5]}
           rows={reklame}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
   
    </Box>
     )}
     </>
  );
};

export default Reklame;
