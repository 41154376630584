import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, useTheme, Button, TextField ,} from "@mui/material";
import { makeStyles } from '@mui/styles'; 
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import Header from "../../components/Header";
import { Routes, Route, useParams, Link, useNavigate } from 'react-router-dom';
import { getMembersByGym, deleteMember } from "../../api/memberApi";
import { deleteUser} from "../../api/userApi";
import { EndDateConverting, EndDateConvertingWithTime, StartDateConverting, StartDateConvertingWithTime } from '../../components/DateConverting';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import RemainingDays from "../../components/RemainingDays";
import { TailSpin } from 'react-loader-spinner';
import { UserContext } from '../../context/UserProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { tokens } from "../../theme";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as XLSX from 'xlsx';



const useStyles = makeStyles({
  blackBorder: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'black', // Postavlja boju granice na crnu
      },
      '& input': {
        color: 'black', // Postavlja boju teksta na crnu
      },
    },
  },
});
const Membership = (props) => {
  const { user } = useContext(UserContext);

  let gymId = useParams();
  // console.log(gymId.gymID)
  const userRole = user?.role;
  const navigate = useNavigate();
  const isEmpleyee = user?.role === 'employee';
  const classes = useStyles();
  let newGymId = gymId?.gymID || props?.data?._id;
  const theme = useTheme();
  const [membership, setMembership] = useState([]);
  const colors = tokens(theme.palette.mode);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);

  const [password, setPassword] = useState('');
  // const [isPasswordCorrect, setPasswordCorrect] = useState(false);
// console.log(membership)

  const handleExportToExcel = () => {
    const exportData = membership.map((item) => ({
      Ime: item.user.firstName,
      Prezime: item.user.lastName,
      // Opis: item.opis,
      Paket: item.user.membership.plan.nazivPaketa,
      email: item.user.email,
       Clanarina: item.user.membership.status,
    }));
  
    const worksheet = XLSX.utils.json_to_sheet(exportData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'User');
  
    // Export the workbook to an Excel file
    XLSX.writeFile(workbook, `User.xlsx`);
  };
  

// console.log(memberToDelete?.row?.user?._id)
  useEffect(() => {
    async function fetchPaket() {
      try {
        await getMembersByGym(newGymId).then((response) => {
          let filteredMemberships = response;
          filteredMemberships.sort((a, b) => new Date(b.user.createdAt) - new Date(a.user.createdAt));

          if (isEmpleyee) {
            filteredMemberships = response.filter(
              (membership) => membership?.payments[membership?.payments?.length - 1]?.status == "pending" || membership.user.membership.status === "expired" || membership.user.membership.status === "pending" || membership.user.membership.status === "cancelled"
            );
          }

          setMembership(filteredMemberships);
          setLoading(false);
        });
      } catch (error) {
        console.error(error);
      }
    }

    fetchPaket();
  }, [newGymId, isEmpleyee]);

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const filteredMembership = membership.filter((member) => {
    if (member?.user) {
      const firstNameMatch = member.user.firstName.toLowerCase().includes(searchValue.toLowerCase());
      const lastNameMatch = member.user.lastName.toLowerCase().includes(searchValue.toLowerCase());
      return firstNameMatch || lastNameMatch;
    }
    return false;
  });

  const userRender = (cell) => {
    if (!cell?.row || !cell.row.user) {
      return <p>No user data available</p>;
    }
    return (
      <p direction="row" spacing={1}>
        {cell.row.user.firstName} {cell.row.user.lastName}
      </p>
    );
  }

  const gymRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
        {cell.row.default_gym.naziv}
      </p>
    )
  }

  const planRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
        {cell.row?.user.membership.plan?.nazivPaketa.bs || cell.row?.user.membership.plan?.nazivPaketa}
        {cell?.row?.user.membership.notes?.length > 0 ? <Link to={`/membership/${cell?.row?.user.membership?._id}/notes`}>
          <Badge badgeContent={cell?.row?.user.membership.notes?.length} color="secondary" style={{ padding: "7px" }}>
          </Badge>
        </Link> : ""}
      </p>
    )
  }

  const startDateRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
        <StartDateConverting date={cell.row?.user.membership.startDate}/>
      </p>
    )
  }

  const endDateRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
        <EndDateConverting date={cell.row?.user.membership.endDate}/>
        {/* <StartDateConverting date={cell.row?.user.membership.endDate}/> */}
      </p>
    )
  }

  const placanjeRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
        {cell?.row?.payments[cell?.row?.payments?.length - 1]?.paymentMethod}
      </p>
    )
  }

  // const editRender = (cell) => {
  //   return (
  //     <Link data={cell} to={`/users/${cell?.id}/edit`}>
  //       <EditIcon></EditIcon>
  //     </Link>
  //   )
  // }

  const handleDelete = (cell) => {
    setMemberToDelete(cell);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (memberToDelete) {
      const memberIdToDelete = memberToDelete.row._id;
      const uposlenik = user._id;
      // console.log(memberToDelete?.row?.user?._id)
      try {
        // setLoading(true);
        const response = await deleteUser(memberToDelete?.row?.user?._id, {password,uposlenik});
        // await deleteMember(memberIdToDelete);
        // console.log(response);
        toast.success('Uspješno se izvršili brisanja člana.');
        // if(res){
        //   toast.success('Uspješno se izvršili brisanja člana.');
        // }else{
        //   toast.error('Upsss. Pojavio se problem prilikom brisanja člana.');
        // }
        setMembership((prevMembership) =>
          prevMembership.filter((member) => member._id !== memberIdToDelete)
        );
      } catch (error) {
        toast.error('Greška prilikom brisanja člana.');
        console.error("Greška prilikom brisanja člana:", error);
      } finally {
        setMemberToDelete(null);
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setMemberToDelete(null);
    setDeleteDialogOpen(false);
    setPassword('');
  };

  const fotografijaRender = (cell) => {
    if (!cell?.row || !cell.row.user) {
      return null;
    }

    const handleClick = () => {
      navigate(`/users/${cell.row.user._id}`, { state: cell.row });
    };

    return (
      <>
        <img
          alt="Slika nije pronadjena"
          src={cell.row.user.photo}
          style={{ width: "75%" }}
          onClick={handleClick}
        />
      </>
    );
  };
 
  const ostaloDana = (cell) => {
    const startDate = new Date(cell.row.user.membership.startDate)
    const endDate = new Date(cell.row.user.membership.endDate);
    return (
      <p direction="row" spacing={1}>
        <RemainingDays startDate={startDate} endDate={endDate} membership={cell.row.user.membership} />
      </p>
    )
  }

  const statusRender = (cell) => {
    return (
      <p direction="row" spacing={1}>
     
        {cell.row.user.membership.status === "active" ?
         <Badge badgeContent={cell.row.user.membership.status} color="secondary" style={{ padding: "7px" }}></Badge>
          : cell.row.user.membership.status === "pending" ?
          <Badge badgeContent={cell.row.user.membership.status} color="warning" style={{ padding: "7px" }}></Badge>
          : <Badge badgeContent={cell.row.user.membership.status} style={{ padding: "7px", color:"red" }}></Badge>
          }
        {/* {cell.row.user.membership.status} */}
      </p>
    )
  }

  const availableStartTimeRender = (cell) => {
    const startTime = cell.row.user.membership.availableStartTime
    return (
      <p direction="row" spacing={1}>
        {startTime ? startTime : "-"}
      </p>
    )
  }

  const availableEndTimeRender = (cell) => {
    const endTime = cell.row.user.membership.availableEndTime
    return (
      <p direction="row" spacing={1}>
        {endTime ? endTime : "-"}
      </p>
    )
  }

  const maxVisitsPerDayRender = (cell) => {
    const maxVisits = cell.row.user.membership.maxVisitsPerDay
    return (
      <p direction="row" spacing={1}>
        {maxVisits ? maxVisits : "-"}
      </p>
    )
  }

  const remainingVisitsRender = (cell) => {
    const remVisits = cell.row.user.membership.maxVisits
    return (
      <p direction="row" spacing={1}>
        {remVisits ? remVisits : "-"}
      </p>
    )
  }

  const clanskaRender = (cell) => {
    const brClanske = cell.row.user.membership.brojClanske
    return (
      <p direction="row" spacing={1}>
        {brClanske ? brClanske : "-"}
      </p>
    )
  }

  const columns = [
    { field: 'brojClanske', headerName: 'br.clanske', flex: 0.5, width: 50, renderCell: (params) => clanskaRender(params) },
    { field: 'photo', headerName: 'Foto', renderCell: (params) => fotografijaRender(params) },
    { field: 'user', headerName: 'Clan', renderCell: (params) => userRender(params) },
    { field: 'default_gym', headerName: 'Poslovnica', renderCell: (params) => gymRender(params) },
    { field: 'plan', headerName: 'Paket', width: 200, renderCell: (params) => planRender(params) },
    { field: 'payments', headerName: 'Placanje', renderCell: (params) => placanjeRender(params) },
    { field: 'startDate', headerName: 'Pocetak paketa', renderCell: (params) => startDateRender(params) },
    { field: 'endDate', headerName: 'Kraj paketa',renderCell: (params) => endDateRender(params) },
    { field: '-', headerName: 'Ostalo dana', width: 60, renderCell: (params) => ostaloDana(params) },
    { field: 'status', headerName: 'Status', flex: 0.5,sortable: true, renderCell: (params) => statusRender(params) },
    { field: 'availableStartTime', headerName: 'Dostupno vrijeme početka', flex: 1, renderCell: (params) => availableStartTimeRender(params) },
    { field: 'availableEndTime', headerName: 'Dostupno vrijeme kraja', flex: 1, renderCell: (params) => availableEndTimeRender(params) },
    { field: 'maxVisitsPerDay', headerName: 'Makskimalan broj posjeta', flex: 1, renderCell: (params) => maxVisitsPerDayRender(params) },
    { field: 'maxVisits', headerName: 'Broj preostalih posjeta', flex: 1, renderCell: (params) => remainingVisitsRender(params) },
    // { field: 'yyy', headerName: 'Uredi', width: 50, renderCell: (params) => editRender(params) },
    { field: 'ccc', headerName: 'Brisi', width: 50, renderCell: (params) => {
      // console.log(params.row.user.role)
      return (
        <p direction="row" spacing={0}>
         {user?.role === "admin" && params.row.user.role !== "trainer" && (
          <DeleteIcon
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => handleDelete(params)}
          />
        )}
        </p>
      );
    }},
  ];

  return (
    <Box m="20px">
      <Header title="CLANOVI" />
      <ToastContainer />
      <Box display="flex" justifyContent="end" mt="20px">
        <Link to="/users/new">
          <Button type="submit" color="secondary" variant="contained">
            Novi član
          </Button>
        </Link>
        <Link to="/membership-renewal">
          <Button type="submit" color="secondary" variant="contained" style={{ marginLeft: "20px", backgroundColor: "#ffbf02" }}>
            Uredi čalanarinu
          </Button>
        </Link>
      </Box>
      {loading ? (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
          <TailSpin height={80} width={80} />
        </div>
      ) : (
        <Box
          m="40px 0 0 0"
          height="70vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
        
        <Box
          display="flex"
          justifyContent="flex-end"
          marginTop="0px" // Podesite ovo prema potrebi
          marginRight="10px" // Podesite ovo prema potrebi
        >
          {userRole === 'admin'  && (
          <Button onClick={handleExportToExcel} variant="contained" color="secondary">
          Izvezi u Excel
          </Button>
          )}
          </Box>
          <TextField
            id="search"
            label="Pretraga"
            variant="outlined"
            size="small"
            value={searchValue}
            onChange={handleSearch}
            style={{ marginBottom: "10px", width: "250px" }}
          />
          <DataGrid
            pageSize={9}
            rowsPerPageOptions={[5]}
            rows={filteredMembership}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            getRowId={(row) => row._id}
          />
        </Box>
      )}
      {/* Dijalog za potvrdu brisanja */}

      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Unesite lozinku za potvrdu brisanja računa:
          </DialogContentText>
          {memberToDelete?.row?.user?.firstName + " " + memberToDelete?.row?.user?.lastName }
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.blackBorder} 
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
};

export default Membership;
