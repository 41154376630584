import React, { useState, useEffect,useContext } from 'react';
import { GymContext } from "../../theme";
import { makeStyles } from '@material-ui/core/styles';
import Header from "../../components/Header";
import {useLocation} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import {
  Box,
  FormControl,
  InputLabel,
  Button,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { getAllExerciseByGym } from '../../api/exerciseApi';
import Modal from '@material-ui/core/Modal';

// import Autocomplete from '@mui/material/Autocomplete';
import { updateTreningProgramByID } from '../../api/treningProgramApi';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {TailSpin} from 'react-loader-spinner';
import { getAllMeal } from '../../api/mealApi';
import Autocomplete from "@mui/material/Autocomplete";
import METTableModal from '../../components/MET';
import { getMembersByGym } from "../../api/memberApi";
import { getAllJelovnik } from '../../api/jelovnikApi';
import muscleGroupColors  from '../../config/muscleGroupColors';


const useStyles = makeStyles((theme) => ({
  form: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr', // Dvije kolone
    gap: theme.spacing(2),
    // color: "white",
    // maxWidth: '800px',
    margin: '0 auto',
  },
  box2: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    
    // maxWidth: '800px',
    marginTop:20,
    margin: '0 auto',
  },
  inField:{
      backgroundColor:"#ffffff14",
      color: "white",
  },
  inFieldVj:{
    backgroundColor:"#ffffff14",
    width: 390,
    color: "white",
    marginRight:10
  }

}));

function TrainingProgramEdit() {
const { state: data } = useLocation();

    // console.log(data)
    const dataProgram = data[0];
    const dataMember = data[1];
    
    const programId = data[0]._id;
    // console.log(dataProgram);
    // console.log(dataMember);


  const { defaultGym  } = useContext(GymContext);
  const classes = useStyles();


 //bs
  const [name, setName] = useState(data.name.bs);
  const [description, setDescription] = useState(data.description.bs);
  //en
  const [nameEn, setNameEn] = useState(data.name.en);
  const [descriptionEn, setDescriptionEn] = useState(data.description.en);
  const [durationProgram, setDuration] = useState(dataProgram.duration);
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [intensity, setIntensity] = useState(dataProgram.intensity);
  const [goal, setGoal] = useState(dataProgram.goal);
  const [level, setLevel] = useState(dataProgram.level);
  const [jelovnici, setJelovnici] = useState([]);
  const [image, setImage] = useState(dataProgram.image);
  const [selectedJelovnik, setSelectedJelovnik] = useState(dataProgram.jelovnici);

  const [members, setMembers] = useState(dataMember);
  const [member, setMember] = useState("");
  const [type, setType] = useState(dataProgram.type);
  // const [nutrition, setNutrition] = useState([]);
  
  const [exerciseOptions, setExerciseOptions] = useState([]);
  const [mealKinds, setMealKinds] = useState([]);

  const [days, setDays] = useState(dataProgram.days);
  // console.log("days",days)
  // const [dayOpis, setDayOpis] = useState('');
  const [loading, setLoading] = useState(true);
  const mealTypes = ['Doručak', 'Ručak', 'Večera', 'Međuobrok'];
  const [open, setOpen] = useState(false);

// console.log(selectedJelovnik)

const handleViewMet = () => {
  setOpen(true);
};

const handleCloseModal = () => {
  setOpen(false);
};

  useEffect(() => {
    async function fetchData() {
      try {
        await getAllMeal()
        .then((response) => {
          // console.log("response",response)
          setMealKinds(response);
            setLoading(false);
          });
        // setNutricionist(fetchedData);
        // setLoading(false);
      } catch (error) {
        console.error(error);
      }
    }

    fetchData();
  }, []);

  useEffect(()=>{
    async function fachData(){
      const fetchedData = await getAllExerciseByGym(defaultGym._id);
      setExerciseOptions(fetchedData)
  
    }
    fachData()
  },[defaultGym._id])

  useEffect(() => {
    async function fetchMembers() {
      try {
        await getMembersByGym(defaultGym._id)
        .then((response) => {
          setMembers(response);
          // console.log("response",response)
          // setLoading(false);
        });
        // setMembership(fetchedMembership);
      } catch (error) {
        console.error(error);
      }
    }
    if(type === 'individual'){
      fetchMembers();
    }
  }, [defaultGym._id, type]);

  useEffect(() => {
    async function fetchData() {
      const fetchedData = await getAllJelovnik();
      // console.log(fetchedData)
      setJelovnici(fetchedData.jelovnici);
      // setIsLoaded(true);
    }
    fetchData();
  }, []);


  const handleAddMember = (event) => {
    setMember(event.target.value);
  };

  // console.log("exerciseOptions", exerciseOptions)
const handleDayOpisChange = (dayIndex, value) => {
  setDays((prevDays) => {
    const newDays = [...prevDays];
    newDays[dayIndex].dayOpis = value;
    return newDays;
  });
};
  // const handleDayOpisChange = (dayIndex, value) => {
  //   const newDays = [...days];
  //   newDays[dayIndex].dayOpis = value;
  //   setDays(newDays);
  // };

  const handleAddExercise = (dayIndex) => {
    // console.log("dayIndex", dayIndex)
    const newDays = [...days];
    // console.log("newDays",newDays)
    const exercises = newDays[dayIndex].exercises;
    exercises.push({ exercise: '', exerciseId: '',sets: 0, repetitions: [], rest: [], heavy:[] });
    newDays[dayIndex].exercises = exercises;
    setDays(newDays);
  };

 const handleMealChange = (dayIndex, mealIndex, mealKind) => {
  const newDays = [...days];
  const meal = newDays[dayIndex].meals[mealIndex];
  meal.mealKind = mealKinds.find((m) => m.name === mealKind);
  if (meal.mealKind) {
    let sumGrams = 0;
    meal.mealKind.foods.map((e)=>sumGrams += e.grams)
    meal.grams = sumGrams;
    meal.proteins = meal.mealKind.proteins;
    meal.carbs = meal.mealKind.carbs;
    meal.fats = meal.mealKind.fats;
  }
  newDays[dayIndex].meals[mealIndex] = meal;
  setDays(newDays);
};

  const handleExerciseRemove = (dayIndex, exerciseIndex) => {
    const newDays = [...days];
    const exercises = newDays[dayIndex].exercises.filter((_, index) => index !== exerciseIndex);
    newDays[dayIndex].exercises = exercises;
    setDays(newDays);
  };

  const handleAddMeal = (dayIndex) => {
    const newDays = [...days];
    const meals = newDays[dayIndex].meals;
    meals.push({ 
      description:mealKinds[0].description,
      fiber:mealKinds[0].fiber,
      name:mealKinds[0].name,
      time:mealKinds[0].time,
      unsaturatedFatt:mealKinds[0].unsaturatedFatt,

      type: mealTypes[0], 
      id:mealKinds[0]._id,
      proteins: 0, 
      carbs: 0, 
      fats: 0,
      mealKind: mealKinds[0].name // set default meal kind
    });
    newDays[dayIndex].meals = meals;
    setDays(newDays);
  };
    
  const handleMealTypeChange = (dayIndex, mealIndex, type) => {
    const newDays = [...days];
    newDays[dayIndex].meals[mealIndex].type = type;
    setDays(newDays);
  };

  const handleAddDay = () => {
    const newDays = [...days];
    const day = newDays.length + 1;

    const exercises = [
      // { exercise: '', sets: 0, repetitions: [], rest: [] }
    ];
    // console.log("exercises",exercises)
    const meals = [
      // { type: 'Doručak', grams: 0, proteins: 0, carbs: 0, fats: 0 },
      // { type: 'Ručak', grams: 0, proteins: 0, carbs: 0, fats: 0 },
      // { type: 'Večera', grams: 0, proteins: 0, carbs: 0, fats: 0},
      // { type: 'Međuobrok', grams: 0, proteins: 0, carbs: 0, fats: 0 },
      ];
      newDays.push({ day, exercises, meals });
      setDays(newDays);
      };
     
      const handleExerciseChange = (dayIndex, exerciseIndex, newValue) => {
        setDays(prevDays => {
          const newDays = [...prevDays];
          if (newValue) {
            newDays[dayIndex].exercises[exerciseIndex].exerciseId = newValue._id;
            newDays[dayIndex].exercises[exerciseIndex].exercise = newValue.name;
          } else {
            newDays[dayIndex].exercises[exerciseIndex].exerciseId = '';
            newDays[dayIndex].exercises[exerciseIndex].exercise = '';
          }
          return newDays;
        });
      };
      
      
      
      const handleTrajanjeVjezbeChange = (dayIndex, exerciseIndex, value) => {
        const newDays = [...days];
        newDays[dayIndex].exercises[exerciseIndex].duration = value;
        setDays(newDays);
      };
      const handleMETVjezbeChange = (dayIndex, exerciseIndex, value) => {
        const newDays = [...days];
        newDays[dayIndex].exercises[exerciseIndex].met = value;
        setDays(newDays);
      };

      const handleAddSet = (dayIndex, exerciseIndex) => {
      const newDays = [...days];
      const sets = newDays[dayIndex].exercises[exerciseIndex].sets;
      const repetitions = new Array(sets).fill({ reps: '', rest: '',heavy:'' });
      newDays[dayIndex].exercises[exerciseIndex].repetitions = repetitions;
      setDays(newDays);
      };
      
      const handleSetsChange = (dayIndex, exerciseIndex, newSets) => {
        setDays(currentDays => {
          // Duboka kopija trenutnog stanja dana
          const newDays = JSON.parse(JSON.stringify(currentDays));
      
          // Pronalaženje odgovarajuće vježbe
          const exercise = newDays[dayIndex].exercises[exerciseIndex];
      
          // Pretvaranje broja setova iz stringa u broj
          const setsCount = parseInt(newSets, 10) || 0;
      
          // Ažuriranje broja setova
          exercise.sets = setsCount;
      
             // Inicijalizacija nizova ako su undefined
          exercise.repetitions = exercise.repetitions || [];
          exercise.rest = exercise.rest || [];
          exercise.heavy = exercise.heavy || [];
          
          // Ažuriranje nizova repetitions, rest, i heavy
          while (exercise.repetitions.length < setsCount) {
            exercise.repetitions.push({ reps: '', heavy: '',rest: '' });
          }
          if (exercise.repetitions.length > setsCount) {
            exercise.repetitions.length = setsCount;
            exercise.rest.length = setsCount;
            exercise.heavy.length = setsCount;
          }
      
          return newDays;
        });
      };
      
// Ažuriranje broja ponavljanja za specifičnu seriju
const handleRepetitionsChange = (dayIndex, exerciseIndex, setIndex, reps) => {
  setDays(prevDays => {
    const newDays = [...prevDays];
    newDays[dayIndex].exercises[exerciseIndex].repetitions[setIndex].reps = reps;
    return newDays;
  });
};

// Ažuriranje težine za specifičnu seriju
const handleHeavyChange = (dayIndex, exerciseIndex, setIndex, heavy) => {
  setDays(prevDays => {
    const newDays = [...prevDays];
    newDays[dayIndex].exercises[exerciseIndex].repetitions[setIndex].heavy = heavy;
    return newDays;
  });
};

// Ažuriranje vremena odmora za specifičnu seriju
const handleRestChange = (dayIndex, exerciseIndex, setIndex, rest) => {
  setDays(prevDays => {
    const newDays = [...prevDays];
    newDays[dayIndex].exercises[exerciseIndex].repetitions[setIndex].rest = rest;
    return newDays;
  });
};      
        
     
      // console.log("days",days)
      // const handleImageUpload = (event) => {
      //   const file = event.target.files[0];
      //   setImage(file);
      // };
      const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

      const handleImageUpload = async (event) => {
        const imageFile = event.target.files[0];
        
        if (imageFile) {
          // Proverite veličinu slike
          if (imageFile.size > MAX_IMAGE_SIZE) {
            alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
            event.target.value = null; // Poništite odabir slike
            return;
          }
      
          setImage(imageFile);
          // setPendingImage("1");
        }
      };

      const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        // Ovdje možete izvršiti logiku za spremanje novog programa treninga
        // Na primjer, pozvati API endpoint za stvaranje novog programa
    
        const newProgram = {
          name: name,
          // image,
          description: description,
          duration: durationProgram,
          intensity: intensity,
          goal: goal,
          level: level,
          type: type,
          // jelovnik: selectedJelovnik,
          jelovnik: selectedJelovnik.map(function(objekat) {
            return objekat._id;
        }),
          gym: defaultGym._id,
          days: days.map((day) => {
            return {
              day: day.day,
              dayOpis: day.dayOpis,
              exercises: day.exercises.map((exercise) => {
                // console.log(exercise)
                return {
                  exerciseId: exercise.exerciseId._id,
                  sets: exercise.sets,
                  repetitions: exercise.repetitions,
                  duration: exercise.duration,
                  met: exercise.met,
                  heavy: exercise.heavy,
                  rest: exercise.rest,
                  _id:exercise._id
                };
              }),
              meals: day.meals.map((meal) => {
                return {
                  type: meal.type,
                  mealKind: meal.mealKind.name,
                  grams: meal.grams,
                  proteins: meal.proteins,
                  carbs: meal.carbs,
                  fats: meal.fats,
                  id: meal.mealKind._id,
                  description: meal.mealKind.description,
                  fiber: meal.mealKind.fiber,
                  name: meal.mealKind.name,
                  time: meal.mealKind.time,
                  unsaturatedFatt: meal.mealKind.unsaturatedFatt,
                };
              }),
            };
          }),
        };
        // console.log(newProgram)
        const formData = new FormData();
        formData.append('name', newProgram.name);
        formData.append('description', newProgram.description);
        formData.append('duration', newProgram.duration);
        formData.append('intensity', newProgram.intensity);
        formData.append('goal', newProgram.goal);
        formData.append('level', newProgram.level);
        formData.append('type', newProgram.type);
        formData.append('gym', newProgram.gym);
        // formData.append('jelovnik', newProgram.jelovnik);
        formData.append('jelovnik', JSON.stringify(newProgram.jelovnik)); 
        // Dodavanje dana, vježbi i obroka
        newProgram.days.forEach((day, dayIndex) => {
          formData.append(`days[${dayIndex}][day]`, day.day);
          formData.append(`days[${dayIndex}][dayOpis]`, day.dayOpis);
        
          day.exercises.forEach((exercise, exerciseIndex) => {
            // console.log("exercise",exercise)
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][exerciseId]`, exercise.exerciseId);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][sets]`, exercise.sets);
            // formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][repetitions]`, exercise.repetitions);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][duration]`, exercise.duration);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][met]`, exercise.met);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][heavy]`, exercise.heavy);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][rest]`, exercise.rest);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][id]`, exercise._id);

            const repetitionsString = JSON.stringify(exercise.repetitions);
            formData.append(`days[${dayIndex}][exercises][${exerciseIndex}][repetitions]`, repetitionsString);
          });
        
          day.meals.forEach((meal, mealIndex) => {
            formData.append(`days[${dayIndex}][meals][${mealIndex}][type]`, meal.type);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][mealKind]`, meal.mealKind);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][grams]`, meal.grams);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][proteins]`, meal.proteins);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][carbs]`, meal.carbs);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][fats]`, meal.fats);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][id]`, meal.id);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][description]`, meal.description);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][fiber]`, meal.fiber);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][name]`, meal.name);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][time]`, meal.time);
            formData.append(`days[${dayIndex}][meals][${mealIndex}][unsaturatedFatt]`, meal.unsaturatedFatt);
          });
        });
        
        // Dodavanje slike
        formData.append('image', image);

        // Kreiranje FormData objekta
        // const formData = new FormData();
        // formData.append("image", image); // Dodavanje slike
  
      
        // // Pretvaranje newProgram objekta u JSON i dodavanje kao string u FormData
        // formData.append("newProgram", JSON.stringify(newProgram));
  
      
//  console.log("formData",formData)
//  console.log("program",newProgram)
        // Poziv API endpointa za stvaranje novog programa treninga
        try{
    
          const res = await updateTreningProgramByID(programId, formData)
          // console.log(res)
          if (res.success === true){
            // toast.success('Uspješno ste azurirali program.');
            setLoading(false)
            setName("")
            setDescription("")
            setDuration("")
            setIntensity("")
            setGoal("")
            setLevel("")
            setJelovnici([])
            setImage("")
            setSelectedJelovnik("")
            setMembers("")
            setMember("")
            setType("")
            setExerciseOptions([])
            setMealKinds("")
            setDays([])
            if(loading === false){
            toast.success('Uspješno ste azurirali program.');
            }
        
          }else{
            setLoading(true)
          }
        }catch(err){
          toast.error('Došlo je do greške prilikom azuriranja programa.');
          console.log(err)
        }
      
    
        // Resetiranje polja forme nakon spremanja
        // setName('');
        // setDescription('');
        // setDuration('');
        // setImage('');
        // setStartDate('');
        // setEndDate('');
        // setIntensity('');
        // setGoal('');
        // setLevel('');
        // setType('');
    
    
      };

// Funkcija za pretvaranje niza id-ova mišićnih grupa u niz imena tih grupa
// function mapMuscleGroupsToNames(muscleGroups) {
//   const names = muscleGroups.map(group => {
//     const groupId = group.split('-')[0]; // Uzimamo samo broj grupe (npr. "7" iz "7-1")
//     return muscleGroupColors[groupId] ? muscleGroupColors[groupId].group : '';
//   });

//   return [...new Set(names)].join(', '); // Uklanja duplikate i pretvara u string
// }

const formatMuscleGroups = (muscleGroupIds) => {
  if (!muscleGroupIds) {
    // Ako nema informacija o grupama mišića, vrati prazan string
    return '';
  }
  // Razdvajanje stringova i izvlačenje jedinstvenih identifikatora grupa mišića
  const uniqueIds = new Set(muscleGroupIds.join(',').split(',').map(id => id.split('-')[0]));

  // Mapiranje identifikatora u nazive grupa mišića i spajanje u string
  return Array.from(uniqueIds).map(id => muscleGroupColors[id]?.group || '').filter(Boolean).join(', ');
};
      return (
        <>
        {loading ? (
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50vh" }}>
              <TailSpin height={80} width={80} />
              </div>
            ) : (
        <Box>
           <Box  style={{padding:20}}>
        <Box className={classes.form}>
          <Header title="Uredi program" subtitle="" />
          <ToastContainer />
        </Box>
        <Box className={classes.form}>
        
      <TextField
        label="Naziv programa"
        className={classes.inField}
        value={name}
        onChange={(e) => setName(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      />
     
      {/* <TextField
        label="Opis programa"
        multiline
        rows={2}
        className={classes.inField}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      /> */}

    <TextField
        label="Trajanje programa (npr 30 dana)"
        className={classes.inField}
        type="number"
        value={durationProgram}
        onChange={(e) => setDuration(e.target.value)}
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      />

       
<FormControl>
  <InputLabel htmlFor="intensity" style={{ color: '#aeaeae' }}>
    Intenzitet
  </InputLabel>
  <Select
    value={intensity}
    variant="outlined"
    className={classes.inField}
    onChange={(e) => setIntensity(e.target.value)}
    required
  >
    <MenuItem value="Nisko">Nisko</MenuItem>
    <MenuItem value="Srednje">Srednje</MenuItem>
    <MenuItem value="Visoko">Visoko</MenuItem>
  </Select>
</FormControl>

      <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            required
          />
  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Razina</InputLabel>
    <Select
     className={classes.inField}
      value={level}
      onChange={(e) => setLevel(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
      // InputProps={{ style: { color: '#aeaeae' } }}
      // InputLabelProps={{ style: { color: '#aeaeae' } }}
    >

      <MenuItem value="pocetni">Pocetni</MenuItem>
      <MenuItem value="srednji">Srednji</MenuItem>
      <MenuItem value="napredni">Napredni</MenuItem>
      <MenuItem value="elite">Elite</MenuItem>
    </Select>
  </FormControl>

  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Cilj</InputLabel>
    <Select
     className={classes.inField}
      value={goal}
      onChange={(e) => setGoal(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
    >
      <MenuItem value="fatloss">Gubitak masti</MenuItem>
      <MenuItem value="musclebuilding">Izgradnja mišića</MenuItem>
      <MenuItem value="transformation">Transformacija</MenuItem>
      <MenuItem value="strength">Snaga</MenuItem>
      <MenuItem value="endurance">Izdržljivost</MenuItem>
      <MenuItem value="flexibility">Fleksibilnost</MenuItem>
    </Select>
  </FormControl>
  <FormControl>
    <InputLabel style={{ color: '#aeaeae', marginLeft:10 }}>Tip programa</InputLabel>
    <Select
     className={classes.inField}
      value={type}
      onChange={(e) => setType(e.target.value)}
      required
      variant="outlined"
      style={{ color: 'white' }}
    >
      <MenuItem value="group">Grupni</MenuItem>
      <MenuItem value="individual">Individialni</MenuItem>
    </Select>
  </FormControl>
  <TextField
        label="Opis programa"
        multiline
        rows={8}
        className={classes.inField}
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        variant="outlined"
        required
        InputProps={{ style: { color: '#aeaeae' } }}
        InputLabelProps={{ style: { color: '#aeaeae' } }}
      />
  {type === "individual" ?
  <>
       <InputLabel id="demo-simple-select-label">Odaberi paket</InputLabel>
       <Select
           labelId="demo-simple-select-label"
           id="demo-simple-select"
           value={member}
           label="Paket"
           onChange={handleAddMember}
       >
         {members?.map((e, key)=>{
           return <MenuItem key={key} value={e}>{e?.firstName} {e?.lastName}</MenuItem>
         })
         }
        
       </Select>
       </>
        :""
      }
    

    <Autocomplete
  multiple
  options={jelovnici}
  className={classes.inField}
  getOptionLabel={(option) => option.name}
  value={selectedJelovnik}
  onChange={(event, newValue) => {
    setSelectedJelovnik(newValue);
  }}
  renderInput={(params) => (
    <TextField {...params} label="Prijedlog jelovnika" variant="outlined" />
  )}
/>
  </Box>
  </Box>
      <div style={{ margin: 20 }}>
      <Typography variant="h6">Sedmični plan treninga i prehrane</Typography>
      <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={handleAddDay}
      >
      Dodaj dan
      </Button>
      <Button
      type="button"
      variant="contained"
      color="secundary"
      style={{ marginBottom: 10, marginLeft:20 }}
      onClick={handleViewMet}
      >
      MET lista
      </Button>
      <Modal open={open} onClose={handleCloseModal}>
        <div style={{ width:"50%",position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', background: '#fff', padding: 20 }}>
          <h2 style={{color: "black"}}>Tablica vježbanja za MET (metabolički ekvivalent zadatka)</h2>
          {/* Ovdje možete prikazati tablicu s MET brojevima za različite vježbe */}
          <METTableModal/>
        </div>
      </Modal>
      {days.map((day, dayIndex) => (
      <div
      key={dayIndex}
      style={{
      marginBottom: 20,
      background: '#f5f5f5',
      padding: '10px',
      }}
      >
      <Typography
      variant="subtitle1"
      style={{ marginBottom: 5, color: 'black', fontSize: 20 }}
      >
      Dan {day.day}
      <TextField
        // label=""
        type="text"
        style={{ minWidth: 200, marginRight: 10 }}
        variant="outlined"
        required
        value={day.dayOpis}
        onChange={(e) => handleDayOpisChange(dayIndex, e.target.value)}
        // onChange={handleDayOpisChange}
      />
      </Typography>


      <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={() => handleAddExercise(dayIndex)}
      >
      Dodaj vježbu
      </Button>
      {day.exercises.map((exercise, exerciseIndex) => (

     <div key={exerciseIndex} style={{ marginBottom: 10 }}>
      
        {/* {console.log("exerciseID",exercise.exerciseId._id)} */}
        <Autocomplete
         value={exerciseOptions.find(opt => opt._id === exercise.exerciseId) || exercise.exerciseId}
  // value={day.exercises[exerciseIndex].exerciseId ? exerciseOptions.find(option => option._id === day.exercises[exerciseIndex].exerciseId) : day.exercises[exerciseIndex].exerciseId}
  onChange={(event, newValue) => {
    handleExerciseChange(dayIndex, exerciseIndex, newValue);
  }}
  getOptionLabel={(option) => `${option.name} (${formatMuscleGroups(option.muscleGroups)}) - ${option.type}`}
  renderOption={(props, option) => (
    <Box component="li" {...props}>
      <img
        src={option.image} // Pretpostavka da imate URL slike
        alt={option.name}
        style={{ width: 30, height: 30, marginRight: 10 }}
      />
      {`${option.name} -- ${option.muscleGroups ? formatMuscleGroups(option.muscleGroups) : ''} --${option.type}`}
    </Box>
  )}
  options={exerciseOptions}
  style={{ minWidth: 400, marginRight: 10 }}
  renderInput={(params) => <TextField {...params} label="Vježba" />}
/>

<TextField
  label="Broj serija"
  type="number"
  style={{ minWidth: 200, marginRight: 10 }}
  value={exercise.sets}
  onChange={(e) => handleSetsChange(dayIndex, exerciseIndex, e.target.value)}
/>

     <TextField
       label="Trajanje vježbe (00:00:00)h:m:s"
       type="text"
       style={{ minWidth: 200, marginRight: 10 }}
       value={exercise.duration || "00:00:00"}
       onChange={(e) => handleTrajanjeVjezbeChange(dayIndex, exerciseIndex, e.target.value)}
     />
     <TextField
       label="MET"
       type="text"
       style={{ minWidth: 200, marginRight: 10 }}
       value={exercise.met}
       onChange={(e) => handleMETVjezbeChange(dayIndex, exerciseIndex, e.target.value)}
     />
     <Button
       type="button"
       variant="contained"
       color="secondary"
       style={{ display: "none"}}
       onClick={() => handleAddSet(dayIndex, exerciseIndex)}
     >
       Dodaj seriju
     </Button>
     {exercise.repetitions.map((repetition, setIndex) => (
      <>
      {/* {console.log("repetition",repetition)} */}
       <div key={setIndex} style={{ marginBottom: 10 }}>
         <TextField
           label={`Ponavljanja - Serija ${setIndex + 1}`}
           type="number"
           style={{ minWidth: 200, marginRight: 10 }}
        //    value={repetition}
             value={repetition.reps}
           onChange={(e) =>
             handleRepetitionsChange(dayIndex, exerciseIndex, setIndex, e.target.value)
           }
         />
         <TextField
           label="Opterećenje kg"
           type="number"
           style={{ minWidth: 200 }}
            //value={exercise?.heavy[setIndex]}
            value={repetition?.heavy}
           onChange={(e) => handleHeavyChange(dayIndex, exerciseIndex, setIndex, e.target.value)}
         />
         <TextField
           label="Odmor (s)"
           type="number"
           style={{ minWidth: 200 }}
        //    value={exercise?.rest[setIndex]}
            value={repetition?.rest}
           onChange={(e) => handleRestChange(dayIndex, exerciseIndex, setIndex, e.target.value)}
         />
         
       </div>
       </>
     ))}
     <Button
       type="button"
       variant="contained"
       color="secondary"
       style={{ marginLeft: 10 }}
       onClick={() => handleExerciseRemove(dayIndex, exerciseIndex)}
     >
       Ukloni vježbu
     </Button>
   </div>
      ))}
      {/* <Button
      type="button"
      variant="contained"
      color="primary"
      style={{ marginBottom: 10 }}
      onClick={() => handleAddMeal(dayIndex)}
      >
      Dodaj obrok
      </Button> */}
      {day.meals.map((meal, mealIndex) => (
        <>
  <div key={mealIndex} style={{ marginBottom: 10 }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <Select
          label="Vrsta obroka"
          value={meal.type}
          onChange={(e) =>
            handleMealTypeChange(dayIndex, mealIndex, e.target.value)
          }
          style={{ minWidth: 200 }}
        >
          {mealTypes.map((type, index) => (
            <MenuItem key={index} value={type}>
              {type}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Select
          label="Vrsta jela"
          value={meal.mealKind ? meal.mealKind.name : ""}
          onChange={(e) =>
            handleMealChange(dayIndex, mealIndex, e.target.value)
          }
          style={{ minWidth: 200 }}
        >
          {mealKinds.map((kind, index) => (
            <MenuItem key={index} value={kind.name}>
              {/* {console.log("kind", kind.type)} */}
              <>
              {kind.name}  <span style={{ fontSize:10,backgroundColor: 'gray', padding:3, color:"white", borderRadius:10 }}> {kind.type}</span>
              </>
             
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
          <span style={{color:"gray"}}>Grama:</span> {meal.grams?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Proteini (g):</span> {meal.proteins?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Kalorije (g):</span> {meal.mealKind.calories?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Ugljikohidrati (g):</span> {meal.carbs?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Masti (g):</span> {meal.fats?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Nezasicene m.(g):</span> {meal.mealKind.unsaturatedFatt?.toFixed(2)}
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1" style={{ color: "black" }}>
        <span style={{color:"gray"}}>Vlakna (g):</span> {meal.mealKind.fiber?.toFixed(2)}
        </Typography>
      </Grid>
    </Grid>
  </div>
  </>
))}

</div>
))}
</div>
<Box className={classes.form}>
  <Grid container justify="right" style={{marginTop:15, marginLeft:10}} alignItems="right" spacing={2}>
    <Grid item>
    <Button type="submit"  variant="contained" color="secondary" onClick={handleSubmit}>
      Ažuriraj program
    </Button>
  </Grid>
  </Grid>
  </Box>
</Box>
  )}
</>
);
}
export default TrainingProgramEdit;
