import React, { useState, useEffect } from 'react';
import Header from "../../components/Header";
import Grid from '@material-ui/core/Grid';
import { Routes, Route, useParams, Link, useNavigate, useLocation} from 'react-router-dom';
// import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';
import { makeStyles } from '@mui/styles';
import {
  TextField,
  Button,
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { createExercise } from '../../api/exerciseApi';
import { getAllEquipmentsByGymId } from '../../api/equpmentsApi';
import axios from 'axios';
import ConfigApp from '../../config/ConfigApp';
const baseUrl = `${ConfigApp.URL}`;



const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
  },
  inField: {
    backgroundColor: "#ffffff14"
  }
}));

const EditEqupment = () => {
  const classes = useStyles();
  const { state: data } = useLocation();


    //bs
    const [name, setName] = useState(data.name.bs);
    const [description, setDescription] = useState(data.description.bs);
    //en
    const [nameEn, setNameEn] = useState(data.name.en);
    const [descriptionEn, setDescriptionEn] = useState(data.description.en);
  // const [muscleGroups, setMuscleGroups] = useState(muscleGroupe);
  // const [exercises, setEexercise] = useState(exercisesId);
    // console.log("equipment",equipment);
  const [equipments, setEquipments] = useState([])
  const [image, setImage] = useState(data.image);
  const [selectedTipVjezbe, setSelectedTipVjezbe] = useState(data.type);
  const savedGym = JSON.parse(localStorage.getItem('defaultGym'));
  const [loading, setLoading] = useState(true);




// '../../assets/img/tijelo/4-2.png
  const [selectedMuscleGroups, setSelectedMuscleGroups] = useState([]);
  const [selectedImage, setSelectedImages] = useState([]);


  useEffect(() => {
  
  
    const vjezbeSlika = [];
    // muscleGroupe.map((e)=>{
    //     vjezbeSlika.push(`../../assets/img/tijelo/${e}.png`)
    //   })
      setSelectedImages(vjezbeSlika);
  }, [selectedMuscleGroups,image]);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true); // Postavite stanje učitavanja na true prije nego što dohvatite podatke
        const fetchedData = await getAllEquipmentsByGymId(savedGym._id);
        setEquipments(fetchedData);
      } catch (error) {
        // Obrada greške ako dođe do problema pri dohvaćanju podataka
        console.error(error);
      } finally {
        setLoading(false); // Postavite stanje učitavanja na false nakon što dohvatite podatke ili se pojavi greška
      }
    }
    getData();
  }, []);
  

  const handleSubmit = async (event) => {
    event.preventDefault();

    const equpmentData = {
      gym:data.gym,
      qrCode:data.qrCode,
      name: {
        bs: name,
        en: nameEn,
      },
      description: {
        bs: description,
        en: descriptionEn,
      },
      type: selectedTipVjezbe,
      image,
    };

  console.log(equpmentData)
  
      const formData = new FormData();
      formData.append('name', JSON.stringify(equpmentData.name));
      formData.append('description',  JSON.stringify(equpmentData.description));
      // formData.append('exercises', equpmentData.exercises);
      formData.append('image', equpmentData.image);
      formData.append('gym', equpmentData.gym);
      formData.append('qrCode', equpmentData.qrCode);
  
      // console.log(formData)
      // for (const entry of formData.entries()) {
      //   const [key, value] = entry;
      //   console.log(`Ključ: ${key}, Vrijednost: ${value}`);
      // }
      try {
        const res = await axios.put(`${baseUrl}/oprema/${data._id}`, formData);
        // alert('Podaci su uspješno poslani na backend!');
        // console.log(res.data)
         if (res) {
          setName(res.data.name);
          setDescription(res.data.description);
          setImage(res.data.image);
        toast.success('Uspješno ste azurirali opremu.');
        // window.location.href = `/oprema/${res.data._id}/detalj`;
      }
      } catch (error) {
        console.error(error);
        toast.error('Došlo je do greške prilikom kreiranja vježbe.');
        // alert('Došlo je do greške prilikom slanja podataka.');
      }
    
    
  };

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setImage(file);
  };

// console.log(muscleGroups)
return (
  <Grid container justify="center" alignItems="center" style={{ height: '100vh' }}>
    {loading ? (
      // Prikaz učitavanja
      <div>Loading...</div>
    ) : (
      // Prikaz obrasca kada nema učitavanja
      <Grid item xs={6}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Header title="Uredi opremu" subtitle="" />
          <ToastContainer />
  
          <TextField
            className={classes.inField}
            label="Naziv opreme"
            value={name}
            onChange={(event) => setName(event.target.value)}
            variant="outlined"
            required
          />
  
          <TextField
            label="Opis"
            className={classes.inField}
            multiline
            rows={8}
            fullWidth
            variant="outlined"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          />
  
          {typeof image === "string" ? (
            <img
              style={{ width: "100%" }}
              src={image}
              alt="Slika vježbe"
            />
          ) : (
            <img
              style={{ width: "100%" }}
              src={URL.createObjectURL(image)}
              alt="Slika vježbe"
            />
          )}
          <TextField
            label="Slika"
            className={classes.inField}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
    <Header subtitle="Engleski jezik" />
              <TextField
                label="Naziv opreme (Engleski)"
                value={nameEn}
                onChange={(event) => setNameEn(event.target.value)}
                variant="outlined"
                // required
              />
              <TextField
                label="Opis (Engleski)"
                multiline
                rows={4}
                value={descriptionEn}
                onChange={(event) => setDescriptionEn(event.target.value)}
                variant="outlined"
                // required
              />
          <Grid container justify="left" alignItems="left" spacing={2}>
            <Grid item>
              <Button type="submit" variant="contained" color="secondary">
                Ažuriraj opremu
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    )}
  </Grid>
);

  
};

export default EditEqupment;
