import React, {useState, useEffect,useContext} from 'react'
import { Box, Typography, useTheme, Button, TextField } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataContacts } from "../../data/mockData";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
// import { getPaket } from "../../api/paketApi";
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import QuizIcon from '@mui/icons-material/Quiz';
import { deleteGroup, getGroupByGymId } from '../../api/groupApi';
import { getAllReservationByGroupId } from '../../api/reservationsApi';
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import { UserContext } from '../../context/UserProvider'; 
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import { ToastContainer, toast } from 'react-toastify';
// import ConfigApp from '../../config/ConfigApp';
// const imageUrl = `${ConfigApp.URLIMAGE}`;

const Grupe = () => {
  
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const defaultGym = JSON.parse(localStorage.getItem("defaultGym"));
  const userId = localStorage.getItem('userId');
  // const userRole = localStorage.getItem('userRole');
  const { user} = useContext(UserContext);
  const userRole = user?.role
  // console.log(userId);
  const gymID = defaultGym?._id;
  const [grupe, setGroups] = useState([]);
  // const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [groupToDelete, setMemberToDelete] = useState(null);


  useEffect(() => {

    async function fetchGroups() {
      try {
        const fetchedUPaket = await getGroupByGymId(gymID);
        // const fetchedReservations = await getAllReservationByGroupId(grupe._id);
        // console.log(fetchedUPaket)
        setGroups(fetchedUPaket); 
      } catch (error) {
        console.error(error);
      }
    }

    fetchGroups()
  }, []);

  const [groupedReservations, setGroupedReservations] = useState({});

  useEffect(() => {
    const getGroupedReservations = async () => {
      try {
        const reservationsForGroups = await Promise.all(
          grupe.map((group) => getAllReservationByGroupId(group._id))
        );

        const newGroupedReservations = {};

        grupe.forEach((group, index) => {
          newGroupedReservations[group._id] = reservationsForGroups[index];
        });

        setGroupedReservations(newGroupedReservations);
      } catch (error) {
        console.error('Došlo je do pogreške prilikom dohvaćanja rezervacija:', error);
      }
    };

    getGroupedReservations();
  }, [grupe]);

  // console.log(groupedReservations)
  const trainerRender = (cell)=>{
    // console.log(cell.row)
  const firstName = cell?.row?.trainer?.member.user?.firstName;
  const lastName = cell?.row?.trainer?.member.user?.lastName;
  return( 
    <p direction="row" spacing={1}>
      {firstName + " "+lastName}
  </p>
  )
  }

  const handleDelete = (cell) => {
    setMemberToDelete(cell);
    setDeleteDialogOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (groupToDelete) {
      const groupIdToDelete = groupToDelete.row._id;
      const uposlenik = user._id;
      // console.log(memberToDelete?.row?.user?._id)
      try {
        // setLoading(true); 
        const response = await deleteGroup(groupIdToDelete, {uposlenik});
        // await deleteMember(trenerIdToDelete);
        // console.log(response);
     
        if(response.success === true){
          toast.success('Uspješno se izvršili brisanja grupe.');
        }else{
          toast.error('Upsss. Pojavio se problem prilikom brisanja grupe.');
        }
        setGroups((prevGroup) =>
        prevGroup.filter((group) => group._id !== groupIdToDelete)
        );
      } catch (error) {
        toast.error('Greška prilikom brisanja grupe.');
        console.error("Greška prilikom brisanja grupe:", error);
      } finally {
        setMemberToDelete(null);
        setDeleteDialogOpen(false);
      }
    }
  };

  const handleCancelDelete = () => {
    setMemberToDelete(null);
    setDeleteDialogOpen(false);
    // setPassword('');
  };

  
  const rasporedRender = (cell) => {
    const groupId = cell.row._id;
    const reservationsForGroup = groupedReservations[groupId] || [];
  
    return (
      <p>
        {reservationsForGroup.map((reservation, index) => (
          <span key={index}>
            {reservation.day}
            {index < reservationsForGroup.length - 1 && " "}
          </span>
        ))}
      </p>
    );
  };

  const clanoviRender = (cell)=>{
   const x = cell.row?.members?.length;
  //  console.log(cell.row)
    return( 
      <p direction="row" spacing={1}>
        {x}
    </p>
    )
    }


  const groupDetailRender = (cell)=>{
    const group = cell.row;
    // console.log(group)
    const canReserve = group.trainer.member.user._id === userId;
     return( 
      <p direction="row" spacing={1}>
      {canReserve ? (
        <Link
          to={{
            pathname: `/grupe/${cell.row._id}`,
            state: group,
          }}
          style={{ color: "white", textDecoration: "none", fontSize: "15PX" }}
        >
          {cell.row.name.bs}
        </Link>
      ) : (
        // Ako korisnik nema ovlasti za rezervaciju, prikažite samo naziv grupe bez linka
        cell.row.name.bs
      )}
    </p>
     )
     }
     const fotografijaRender = (cell) => {
      const group = cell.row;
      if (!group) {
        return null; // Return null or a placeholder value if user data is undefined
      }
      
      // const isAdmin = localStorage.getItem('userRole') === 'admin'; // Provjera uloge administratora
      const isAdmin = user?.role === 'admin'; 
      const canReserve = isAdmin || group.trainer.user._id === userId;
    
      return (
        <>
          {canReserve ? (
            <Link
              to={{
                pathname: `/grupe/${cell.row._id}`,
                state: group,
              }}
              style={{ color: "white", textDecoration: "none", fontSize: "15PX" }}
            >
              <img
                alt="Slika nije pronađena"
                src={cell.row.image}
                style={{ width: "75%" }}
              />
            </Link>
          ) : (
            // Ako korisnik nema ovlasti za rezervaciju, prikažite samo sliku bez linka
            <img
              alt="Slika nije pronađena"
              src={cell.row.image}
              style={{ width: "75%" }}
            />
          )}
        </>
      );
    };
    
    
  const columns = [
    // { field: "_id", headerName: "ID", flex: 0.5 },
    // { field: "name", headerName: "Naziv grupe" ,flex: 0.5 },
    { field: 'image', headerName: 'Slika', 
    renderCell: (params) => {
        return fotografijaRender(params);
    }
    },
    { field: 'name', headerName: 'Naziv grupe', 
    renderCell: (params) => {
        return groupDetailRender(params);
    },flex: 0.5
    },
    { field: 'trener', headerName: 'Trener', 
    renderCell: (params) => {
        return trainerRender(params);
    }
    },
    {
      field: "maxCapacity",
      headerName: "Kapacitet",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
    field: "raspored",
    headerName: "Raspored",
    flex: 1,
    renderCell: (params) => {
      return rasporedRender(params);
    },
  },
  { field: 'ccc', headerName: 'Brisi',  width: 50,
  renderCell: (params) => {
    return (
      <p direction="row" spacing={0}>
       {user?.role === "admin" &&  (
        <DeleteIcon
          style={{ color: "red", cursor: "pointer" }}
          onClick={() => handleDelete(params)}
        />
      )}
      </p>
    );
  }
  },
    // { field: 'clanovi', headerName: 'Clanovi', 
    // renderCell: (params) => {
    //     return clanoviRender(params);
    // }
    // },
  ];

  return (
    <Box m="20px">
       <Tooltip title='
        Primjer: možemo imati tri grupe članova: VIP, Premium i Regular. 
        Za VIP grupu možemo imati pakete "VIP godišnja članarina", "VIP mjesečna članarina", "VIP tromjesečna članarina", dok za Premium grupu možemo imati pakete "Premium godišnja članarina", "Premium mjesečna članarina", "Premium tromjesečna članarina". Za Regular grupu možemo imati pakete "Godišnja članarina", "Mjesečna članarina", "Tromjesečna članarina".
        '>
          <QuizIcon alignItems="flex-end"/>
</Tooltip>
      <Header
        title="GRUPE"
        // subtitle='
        />
     
      <Box display="flex" justifyContent="end" mt="20px">
      {(userRole === 'admin' || userRole === 'editor') && (
        <>
      <Link to="/users/trener/new">
          <Button type="submit" color="secondary" variant="contained" style={{marginRight:"20px", backgroundColor:"##b5ad32"}}>
            Novi trener
          </Button>
        </Link>
        <Link to="/grupe/new">
          <Button type="submit" color="secondary" variant="contained">
            Nova grupa
          </Button>
        </Link>
        <Link to={`/gym/${gymID}/schedule`}>
          <Button type="submit" color="secondary" variant="contained" style={{marginLeft:"20px", backgroundColor:"##b5ad32"}}>
          <CalendarTodayOutlinedIcon/> Raspored grupa
          </Button>
        </Link>
            </>
                 )}
            </Box>
      <Box
        m="40px 0 0 0"
        height="70vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={grupe}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          getRowId={(row) =>  row._id}
        />
      </Box>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={handleCancelDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Potvrda brisanja</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Da li želite obrisati grupu?
          </DialogContentText>
          {/* {trenerToDelete?.row?.user?.firstName + " " + trenerToDelete?.row?.user?.lastName } */}
          {/* <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Šifra"
            type="password"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className={classes.blackBorder} 
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelDelete} color="primary">
            Otkaži
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            autoFocus
            // disabled={!password || !isPasswordCorrect}
          >
            Obriši
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Grupe;
