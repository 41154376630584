import React, { useState, useEffect } from 'react';
import Header from '../../components/Header';
import Grid from '@material-ui/core/Grid';
import { useParams, useLocation } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Typography,
  Card,
  CardContent,
  Avatar,
  Divider,
  IconButton,
  Box,
  Button,
  TextField,
  Modal,
  Zoom,
  FormControlLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import ConfigApp from '../../config/ConfigApp';
// import InputAdornment from '@mui/material/InputAdornment';
// import VisibilityIcon from '@mui/icons-material/Visibility';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ColorPickerx from '../../components/ColorPicker'; // Dodano za odabir boje
import { getGymById, updateGym } from '../../api/gymApi';

const baseUrl = `${ConfigApp.URL}`;

const useStyles = makeStyles((theme) => ({
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    maxWidth: '400px',
    margin: '0 auto',
    marginBottom: '100px',
  },
  inField: {
    backgroundColor: '#ffffff14',
  },
  avatar:{
    borderRadius:0
  }
}));

const EditGym = () => {
  const classes = useStyles();
  const { gymId } = useParams();
  // const { state: data } = useLocation();
  // const gymId = data._id
// console.log(gymId)
const [response, setResponse] = useState({});
const [gymData, setGymData] = useState({})
  
const [color, setColor] = useState(response.color || '#fff'); // Dodano za odabir boje
const [loading, setLoading] = useState(false);
// const [selectedImage, setSelectedImage] = useState(data.photo); 
const [image, setImage] = useState();
// console.log(response.address)

useEffect(() => {
  async function fetchGym() {
    try {
      const response = await getGymById(gymId);
      setResponse(response);
      setGymData({
        naziv: response?.naziv || '',
        address: response?.address || '',
        city: response?.city || '',
        zipCode: response?.zipCode || '',
        phone: response?.phone || '',
        transakRacun: response?.transakRacun || '',
        email: response?.email || '',
        opis: response?.opis || '',
        kapacitet: response?.kapacitet || '',
        ...response?.workingDays?.reduce((acc, day) => {
          acc[`${day.day}WorkingHours`] = day.hours;
          return acc;
        }, {}),
      });
      setImage(response.photo)
    } catch (error) {
      console.error(error);
    }
  }

  fetchGym();
}, [gymId]);

// const [gymData, setGymData] = useState({
//   naziv: response?.naziv || '',
//   address: response?.address || '',
//   city: response?.city || '',
//   zipCode: response?.zipCode || '',
//   phone: response?.phone || '',
//   transakRacun: response?.transakRacun || '',
//   email: response?.email || '',
//   opis: response?.opis || '',
//   kapacitet: response?.kapacitet || '',
//   ...response?.workingDays?.reduce((acc, day) => {
//     acc[`${day.day}WorkingHours`] = day.hours;
//     return acc;
//   }, {}),
// });


  // console.log(image)
  const handleColorChange = (newColor) => {
    setColor(newColor);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('naziv', gymData.naziv);
    formData.append('address', gymData.address);
    formData.append('city', gymData.city);
    formData.append('zipCode', gymData.zipCode);
    formData.append('phone', gymData.phone);
    formData.append('email', gymData.email);
    formData.append('image', image);
    formData.append('transakRacun', gymData.transakRacun);
    // formData.append('plan', user.plan);
    formData.append('opis', gymData.opis);
    // formData.append('role', gymData.role);
    formData.append('kapacitet', gymData.kapacitet);
    // formData.append('workingDays', gymData);
    // formData.append('date_of_birth',gymData);
    // formData.append('gymData', gymData);

    try {
      setLoading(true);

      // Konstruirajte workingDays objekt i dodajte ga u formData
  const workingDays = days.map((day) => ({
    day,
    hours: gymData[`${day}WorkingHours`],
  }));
  formData.append('workingDays', JSON.stringify(workingDays));
  // formData.append('workingDays', workingDays);

      // console.log(gymId)
      // Otvaranje FormData objekta
// for (const [kljuc, vrednost] of formData.entries()) {
//   console.log(`${kljuc}: ${vrednost}`);
// }
      const response = await updateGym(gymId,formData);
      // const response = await axios.put(`${baseUrl}/gym/${gymId}`, formData);
      // console.log(response)
      setResponse(response);
      setLoading(false);
      toast.success('Uspješno ste izmijenili podatke poslovnice.');
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error('Došlo je do greške.');
    }
  };

  const MAX_IMAGE_SIZE = 200 * 1024; // 200 KB u bajtovima

  // const handleImageUpload = (event) => {
  //   const file = event.target.files[0];
  //   setImage(file);
  // };
  // useEffect(() => {
  // }, [image]);
  const handleImageUpload = async (event) => {
    const imageFile = event.target.files[0];
    
    if (imageFile) {
      // Proverite veličinu slike
      if (imageFile.size > MAX_IMAGE_SIZE) {
        alert('Slika je prevelika. Maksimalna veličina je 200 KB.');
        event.target.value = null; // Poništite odabir slike
        return;
      }
  
      // setSelectedImage(imageFile);
      setImage(imageFile);

    }
  };

  const days = [
    'Ponedjeljak',
    'Utorak',
    'Srijeda',
    'Četvrtak',
    'Petak',
    'Subota',
    'Nedjelja',
  ];

  return (
    <Grid container justify="center" style={{ height: '100vh' }}>
      <Grid item xs={6}>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Header title={`UREDI POSLOVNICU: ${response?.naziv}`} />
          <ToastContainer />
          
            {typeof image === "string" ? (
            <Avatar
                alt={response.name}
                src={image}
                className={classes.avatar}
                sx={{ width: "100%", height: "100%", borderRadius: 0 }}
            />
            ) : (
            <Avatar
                alt={response.name}
                src={image ? URL.createObjectURL(image) : ""}
                className={classes.avatar}
                sx={{ width: "100%", height: "100%", borderRadius: 0 }}
            />
            )}

            <label htmlFor="upload-photo">
              <input
                type="file"
                id="upload-photo"
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleImageUpload}
              />
              <IconButton component="span">
                <AddPhotoAlternateIcon style={{ color: 'white' }} />
              </IconButton>
            </label>
            <InputLabel htmlFor="naziv">Naziv</InputLabel>
          <TextField
            className={classes.inField}
            // label="Naziv"
            id="naziv"
            value={gymData.naziv}
            onChange={(event) => setGymData({ ...gymData, naziv: event.target.value })}
            variant="outlined"
            required
          />
          
          <InputLabel htmlFor="Adresa">Adresa</InputLabel>
          <TextField
            className={classes.inField}
            // label="Adresa"
            id="Adresa"
            value={gymData.address}
            onChange={(event) => setGymData({ ...gymData, address: event.target.value })}
            variant="outlined"
            required
          />
  <InputLabel htmlFor="Grad">Grad</InputLabel>
          <TextField
            className={classes.inField}
            id="Grad"
            value={gymData.city}
            onChange={(event) => setGymData({ ...gymData, city: event.target.value })}
            variant="outlined"
          />

<InputLabel htmlFor="posbr">Poštanski broj</InputLabel>
          <TextField
            className={classes.inField}
            id="posbr"
            value={gymData.zipCode}
            onChange={(event) => setGymData({ ...gymData, zipCode: event.target.value })}
            variant="outlined"
          />
        <InputLabel htmlFor="trr">Transak racun</InputLabel>
        <TextField
            className={classes.inField}
            id="trr"
            type="number"
            value={gymData.transakRacun}
            onChange={(event) => setGymData({ ...gymData, transakRacun: event.target.value })}
            variant="outlined"
          />
           <InputLabel htmlFor="Kapacitet">Kapacitet</InputLabel>
          <TextField
            className={classes.inField}
            id="Kapacitet"
            type="number"
            value={gymData.kapacitet}
            onChange={(event) => setGymData({ ...gymData, kapacitet: event.target.value })}
            variant="outlined"
          />

        <InputLabel htmlFor="Telefon">Telefon</InputLabel>
          <TextField
            className={classes.inField}
            id="Telefon"
            value={gymData.phone}
            onChange={(event) => setGymData({ ...gymData, phone: event.target.value })}
            variant="outlined"
          />

          <InputLabel htmlFor="Email">Email</InputLabel>
          <TextField
            className={classes.inField}
            label="Email"
            type="email"
            value={gymData.email}
            onChange={(event) => setGymData({ ...gymData, email: event.target.value })}
            variant="outlined"
          />

        <InputLabel htmlFor="Opis">Opis</InputLabel>
          <TextField
            className={classes.inField}
            id="Opis"
            multiline
            rows={4}
            value={gymData.opis}
            onChange={(event) => setGymData({ ...gymData, opis: event.target.value })}
            variant="outlined"
          />

          {/* Ovdje dodajte polja za radno vrijeme koristeći mapiranje iz niza "days" */}
       
          {days.map((day) => (
            <>
               <InputLabel htmlFor="rdv">Radno vrijeme za {day}</InputLabel>
                <TextField
                  key={day}
                  id="rdv"
                  className={classes.inField}
                  // label={`Radno vrijeme za ${day}`}
                  value={gymData[`${day}WorkingHours`]}
                  onChange={(event) =>
                    setGymData({
                      ...gymData,
                      [`${day}WorkingHours`]: event.target.value,
                    })
                  }
                  variant="outlined"
                />
                </>
              ))}


          {/* Dodajte ColorPickerx komponentu za odabir boje */}
          <ColorPickerx onColorChange={handleColorChange} color={color} />

          <Button type="submit" variant="contained" color="secondary">
            Sačuvaj promene
          </Button>
        </form>
      </Grid>
    </Grid>
  );
};

export default EditGym;
